const INITIAL_STATE = { data: [] };

let homeReducer = (state = INITIAL_STATE, action) => {
    if (action.type === 'UPDATE_MESSAGE_FEED') {
        const { pagination = {}, data } = action.data || {}
        if (pagination.page !== 1) {
            return { pagination, data: [...state.data, ...data] }
        }
        return action.data
    } else if (action.type === 'LOGOUT') {
        return INITIAL_STATE
    } else {
        return state;
    }
};


export default homeReducer;