import {combineReducers} from "redux";
import userReducer from "./reducers/user";
import tokenReducer from "./reducers/token";
import websocketTokenReducer from "./reducers/websocketToken";
import credentialsReducer from "./reducers/credentials";
import homeReducer from "./reducers/home";
import permissionsReducer from "./reducers/permissions"


const appReducer = combineReducers({
    /* your app’s top-level reducers */
    user: userReducer,
    token: tokenReducer,
    credentials: credentialsReducer,
    home: homeReducer,
    wsToken: websocketTokenReducer,
    permissions: permissionsReducer,
});

export default appReducer;