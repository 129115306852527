import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import history from "./Utilities/history";
const AuthRoute = React.lazy(() => import('./views/components/AuthRoute'));
const MainRoute = React.lazy(() => import('./views/components/MainRoute'));
const Home = React.lazy(() => import('./views/main/Home/Home'));
const Login = React.lazy(() => import('./views/auth/login/Login'));
const SupportRoute = React.lazy(() => import('./views/components/SupportRoute'));
const ContactSupport = React.lazy(() => import('./views/auth/ContactSupport/ContactSupport'));
class App extends Component {
  async componentDidMount() {}
  render() {
    return (
      <BrowserRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <AuthRoute path="/login" name="login" component={Login} />
            <AuthRoute exact path="/" name="login" component={Login} />
            <MainRoute path="/home" name="Home" component={Home} />
            <SupportRoute path="/support" name="ContactSupport" component={ContactSupport} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;