const INITIAL_STATE = true;

let permissionsReducer = (state = INITIAL_STATE, action) => {
    if (action.type === 'UPDATE_PERMISSION') {
        return action.data
    } else if (action.type === 'CLEAR_PERMISSION') {
        return INITIAL_STATE
    } else {
        return state;
    }
};

export default permissionsReducer;